@import "inc/_variables.scss";

body
{
    background-color: $baseText;
    color: $baseInverse;
}

div#skiplinks
{
    a,
    a:link,
    a:hover,
    a:visited,
    a:active,
    p
    {
        position: absolute;
        left: 0;
        top: -500px;
        width: 1px;
        height: 1px;
        overflow: hidden;
        color: #ccc;
    }
}

header#page-header
{
    width: 100%;
    padding: ($sectionPadding / 2) 0 0;

    @media(min-width: $screen-md-min)
    {
        position: absolute;
        z-index: 10;
        background-image: linear-gradient(to bottom, rgba($baseText, 1) 20%, rgba($baseText, 0) 80%);
    }

    #page-logo
    {
        max-height: 70px;
        margin: 0 auto;
    }

    #header-phone
    {
        padding-top: 0.5em;

        > #header-phone-text
        {
            font-size: 1.5em;
            display: inline-block;
            margin: 0 0.75em 0 0;
            text-transform: uppercase;
            font-weight: bold;

            > a
            {
                font-size: 1.25em;
            }
        }

        @media(max-width: $screen-xs-max)
        {
            text-align: center;

            > #header-phone-text
            {
                margin: 1em 0 0;
            }
        }

        @media(min-width: $screen-sm-min) and (max-width: $screen-sm-max)
        {
            padding-top: 0;
            margin-top: -1em;

            #header-phone-text
            {
                margin-bottom: 0.25em;
            }
        }
    }

    a
    {
        font-weight: bold;
        text-decoration: none;
    }

    .list-social
    {
        display: inline-block;

        @media(max-width: $screen-xs-max)
        {
            display: block;
            margin-top: 1em;
            text-align: left;
        }

        @media(min-width: $screen-sm-min) and (max-width: $screen-sm-max)
        {
            margin-bottom: 2em;
            display: block;
            text-align: left;
        }
    }
}

nav#page-navigation
{
    text-transform: uppercase;
    font-size: 1.1em;

    a
    {
        border-radius: 40px;
    }

    .navbar-nav > li > a
    {
        margin: 0 2px;
    }

    .dropdown-menu
    {
        margin-top: 4px;
        padding: 20px;
        border-radius: 40px;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        font-size: 1.05em;

        > li > a
        {
            padding: 0.5em 1em;
            margin: 2px 0;

            @media(max-width: $screen-xs-max)
            {
                white-space: normal;
            }
        }
    }

    .navbar-collapse
    {
        @media(max-width: $screen-md-max)
        {
            position: absolute;
            right: 0;
            background-color: $baseText;
            border: 1px solid $highlight;
            width: 320px;
            z-index: 50;
        }

        @media(max-width: $screen-xs-max)
        {
            max-width: calc(100vw - 30px);
            right: 15px;
        }
    }

    @media(max-width: $screen-xs-max)
    {
        position: absolute;
        top: -55px;
        right: 0;
    }
}

aside#page-slider
{
    padding: 0;

    .carousel-inner > .item
    {
        padding-bottom: 35%;

        > img
        {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 100%;
        }
    }
}

.carousel-indicators li
{
    background-color: $accentBase;
    border: 0;

    &.active
    {
        background-color: $baseInverse;
        border: 1px solid $accentBase;
    }
}

div#page-slider-placeholder
{
    height: 360px;
    position: relative;
    background-image: url(/site/img/banner/contact.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    .slider-strap
    {
        @media(min-width: $screen-md-min)
        {
            top: 67%;
        }

        @media(max-width: $screen-xs-max)
        {
            display: block;
        }
    }

    @media(max-width: $screen-xs-max)
    {
        height: 240px;
        display: none;
    }
}

.slider-strap
{
    position: absolute;
    top: 55%;
    transform: translateY(-50%);
    width: calc(100% - 100px);
    font-family: $altFont;
    text-transform: uppercase;
    left: 50px;

    .slider-strap-main
    {
        background-color: $baseInverse;
        color: $accentBase;
        font-size: 3em;
        padding: 15px 30px 5px;
        display: inline-block;
        transform: skewX(-13deg);
        font-weight: bold;

        @media(max-width: $screen-sm-max)
        {
            font-size: 1.8em;
        }

        @media(max-width: $screen-md-max)
        {
            font-size: 2em;
            padding: 10px 30px 3px;
        }
    }

    .slider-strap-sub
    {
        color: $baseInverse;
        background-color: rgba($baseText, 0.5);
        font-size: 1.5em;
        margin-top: 20px;
        padding: 20px 30px 10px;
        display: inline-block;
        transform: skewX(-13deg);

        @media(max-width: $screen-sm-max)
        {
            font-size: 1.2em;
        }

        @media(max-width: $screen-md-max)
        {
            font-size: 1.4em;
            margin-top: 12px;
            padding: 12px 30px 7px;
        }
    }

    @media(max-width: $screen-xs-max)
    {
        display: none;
    }
}

.carousel-control
{
    width: 60px;
    background-image: none !important;

    > i
    {
        position: absolute;
        top: 55%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: $accentBase;
        font-size: 1.5em;
    }
}

aside#page-motto
{
    border-top: 2px solid $accentBase;
    text-align: center;
    font-size: 1.15em;
    font-family: $altFont;
    text-transform: uppercase;
    letter-spacing: 0.04em;
    padding: ($sectionPadding / 4) 0 14px;

    p
    {
        margin-bottom: 0;
    }

    @media(max-width: $screen-xs-max)
    {
        font-size: 0.85em;
    }
}

aside#page-search
{
    background-color: $accentBase;
    padding: 1em 0;
    text-align: center;

    .form-inline > *
    {
        vertical-align: middle;
        display: inline-block;
    }

    label
    {
        font-size: 1.5em;
        font-weight: 300;
        padding: 3px 0;
        margin: 0;
        text-transform: uppercase;
        line-height: 1.2;

        > i
        {
            margin-right: 0.5em;
        }

        @media(min-width: $screen-md-min) and (max-width: $screen-md-max)
        {
            font-size: 1.2em;
        }
    }

    input
    {
        width: 400px;
        margin: 1em 24px;
        max-width: calc(100% - 48px);

        @media(min-width: $screen-md-min) and (max-width: $screen-md-max)
        {
            width: 360px;
        }
    }

    button
    {
        width: 160px;
        margin: 0;
        border-color: #fff;
    }

    @media(max-width: $screen-xs-max)
    {
        padding: 3em 0;
    }

    @media(min-width: $screen-sm-min) and (max-width: $screen-sm-max)
    {
        padding: 2em 0;
    }
}

article#page-content
{
    background-color: $baseInverse;
    color: $baseText;
    padding: ($sectionPadding / 2) 0;

    &.page-content-bordered
    {
        border-top: 5px solid $accentBase;
    }
}

aside,
section
{
    padding: $sectionPadding 0;

    h3.h1, h1
    {
        text-transform: uppercase;
        text-align: center;
        position: relative;
        padding-bottom: 1em;
        margin-bottom: 2em;

        &:after
        {
            content: "";
            position: absolute;
            border-bottom: $underlineHeight solid;
            width: 100%;
            max-width: 200px;
            top: 100%;
            left: 50%;
            transform: translateX(-50%);
        }

        @media(max-width: $screen-sm-max)
        {
            font-size: 26px;
        }
    }
}

#page-categories
{
    background-color: $accentBase;
    background-image: url(/site/img/bg/generic.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    padding: 55px 0;

    @media(max-width: $screen-sm-max)
    {
        padding: 30px 0;
    }

    .breadcrumb
    {
        a,
        > li + li:before
        {
            color: $baseInverse;
        }
    }
}

.title-wrapper
{
    background: rgba($baseText, 0.5);
    padding: 40px 0 60px;
    margin-bottom: 55px;

    > h3.h1,
    > h1
    {
        margin-bottom: 0;

        &:after
        {
            border-color: $accentBase;
        }
    }

    @media(max-width: $screen-sm-max)
    {
        padding: 20px 0 30px;
        margin-bottom: 30px;
    }

    .page-category-copy
    {

        > :first-child
        {
            margin-top: 32px;
        }

        > :last-child
        {
            margin-bottom: 0;
        }
    }
}

.page-category-copy
{
    padding: 0 1em;
    text-align: center;

    > :first-child
    {
        margin-top: -20px;
    }

    > :last-child
    {
        margin-bottom: 32px;
    }
}

.category-row
{
    display: flex;
    flex-wrap: wrap;
    margin: 0 -7px;

    > .category-block,
    > .category-empty
    {
        flex: 1 1 240px;
        margin: 0 7px 14px;
    }
}

.category-block
{
    display: block;
    background-color: $baseInverse;
    color: $baseText;
    position: relative;
    padding: 20px 20px 70px;
    margin-bottom: 2em;
    transition: transform ease-in-out 0.25s;
    box-shadow: $baseShadow;

    &:hover,
    &:active,
    &:focus
    {
        text-decoration: none;
        transform: scale(1.05);
    }

    > .category-photo
    {
        margin: -20px -20px 25px;
        border-bottom: 5px solid $accentBase;
        position: relative;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        padding-bottom: calc(100% + 35px);
        overflow: hidden;

        &:after
        {
            content: "";
            display: block;
            position: absolute;
            bottom: 0px;
            right: 20px;
            border: 9px solid transparent;
            border-bottom: 12px solid $accentBase;
        }

        h4
        {
            position: absolute;
            display: inline-block;
            background: $baseInverse;
            font-weight: bold;
            padding: 10px 15px 5px 35px;
            transform: skew(-13deg);
            bottom: 0;
            left: -15px;
            max-width: 80%;
        }
    }

    p
    {
        margin-bottom: 0;
    }

    h4
    {
        text-transform: uppercase;
        color: $accentBase;
    }

    &.category-alt-block
    {
        .category-photo
        {
            margin-bottom: 0;
        }

        .btn-primary
        {
            left: 50%;
            transform: translateX(-50%);

            &:hover,
            &:active,
            &:focus
            {
                transform: translateX(-50%) scale(1.05);
            }
        }
    }
}

    .category-block .btn-primary,
    .featured-item .btn-primary
    {
        border-width: 0;
        position: absolute;
        bottom: 20px;
        text-transform: none;
        font-weight: normal;
        transition: transform ease-in-out 0.25s;

        &:hover,
        &:active,
        &:focus
        {
            text-decoration: none;
            transform: scale(1.05);
        }
    }

#page-artists
{
    padding: 20px 0;

    h3.h1:after,
    h1:after
    {
        border-color: $accentBase;
    }
}

aside#page-featured
{
    background-color: $baseText;
    background-image: url(/site/img/bg/artists.svg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    .carousel-indicators
    {
        bottom: -50px;
    }

    @media(max-width: $screen-sm-max)
    {
        .carousel-control
        {
            display: none;
        }
    }

    h3.h1:after,
    h1:after
    {
        border-color: $accentBase;
    }
}

.featured-item
{
    background-color: $baseInverse;
    color: $baseText;
    padding: 30px 60px 80px;
    border-bottom: 5px solid $accentBase;
    position: relative;

    h3
    {
        color: $accentBase;
        font-size: 3.5em;
        font-family: $altFont;
        font-style: italic;
        font-weight: normal;
        text-transform: uppercase;
        margin-top: 0;

        @media(max-width: $screen-xs-max)
        {
            font-size: 2.4em;
        }
    }

    p:first-of-type
    {
        font-weight: bold;
    }

    .featured-image
    {
        padding-bottom: 100%;
        margin: 0 0 -50px 0;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
    }

    @media(max-width: $screen-sm-max)
    {
        padding: 30px 15px 120px;
    }

    @media(max-width: $screen-xs-max)
    {
        .col-sm-4
        {
            max-width: 300px;
            margin: 0 auto;
        }

        .btn-primary
        {
            left: 50%;
            transform: translateX(-50%);
        }
    }
}

.artist-tagline
{
    background-color: $baseText;
    color: $baseInverse;
    text-transform: uppercase;
    padding: 10px 10px 6px;
}

section#artist-profile
{
    padding: 0 0 $sectionPadding;

    h1.artist-name
    {
        text-align: left;
        text-transform: uppercase;
        color: $accentBase;
        margin: 0 0 0.5em;
        padding-bottom: 0;

        &:after
        {
            content: none;
        }
    }

    h2
    {
        margin: 0 0 1em;
    }

    .artist-tagline
    {
        margin-top: 0;
    }

    .artist-description
    {
        margin-bottom: 3em;

        > p:first-of-type
        {
            font-weight: bold;
        }
    }

    .artist-video
    {
        padding: 10px;
        background-color: $highlight;
        border-bottom: 5px solid $accentBase;
        margin-bottom: 3em;
    }

    @media(max-width: $screen-xs-max)
    {
        .col-sm-4
        {
            max-width: 300px;
            margin: 0 auto;
        }
    }
}

.artist-sample
{
    margin-bottom: 2em;

    > audio
    {
        width: 100%;
    }

    &:first-child > h5
    {
        margin-top: 0;
    }

    &:last-child
    {
        margin-bottom: 0;
    }
}

.profile-card
{
    margin-bottom: 1em;
    background-color: #eee;

    > .profile-photo
    {
        padding-bottom: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        border-bottom: 5px solid $accentBase;
        position: relative;

        &:after
        {
            content: "";
            display: block;
            position: absolute;
            bottom: 0px;
            right: 20px;
            border: 9px solid transparent;
            border-bottom: 12px solid $accentBase;
        }
    }

    > .profile-tray
    {
        padding: 1em;
        text-align: center;

        > a
        {
            display: inline-block;
            margin: 0 0.75em;
            text-decoration: none;

            > span
            {

                &.tray-icon
                {
                    display: inline-block;
                    background-color: $accentBase;
                    color: $baseInverse;
                    font-size: 1.5em;
                    width: 2em;
                    height: 2em;
                    line-height: 2.2em;
                    border-radius: 50%;
                }

                &.tray-label
                {
                    display: block;
                    text-transform: uppercase;
                    color: $baseText;
                    font-size: 0.8em;
                    margin-top: 0.5em;
                    font-family: $altFont;
                    font-weight: bold;
                }
            }
        }
    }
}

.profile-categories
{
    margin-bottom: 3em;

    > h3
    {
        margin-top: 30px;
    }

    ul
    {
        color: $accentBase;
        text-transform: uppercase;
        font-size: 0.92em;
        font-weight: bold;
    }
}

.profile-price
{
    font-size: 1.67em;
    text-align: center;
    font-family: $altFont;
    color: $accentBase;
    font-weight: bold;
}

a.profile-enquiry
{
    font-size: 1.333em;
    text-align: center;
    font-family: $altFont;
    text-transform: uppercase;
    color: $baseInverse;
    background-color: $accentBase;
    display: block;
    padding: 1em 1em 0.75em;
    margin-bottom: .5em;
    text-decoration: none;
}

.profile-social
{
    margin-top: 3em;

    h4
    {
        margin-bottom: 0;
    }

    .jssocials-share-link
    {
        border-radius: 50%;
        border-color: $accentBase;
        background-color: $accentBase;
        color: $baseInverse;
        padding: 0.6em;
    }

        .jssocials-share-link:active,
        .jssocials-share-link:focus,
        .jssocials-share-link:hover
        {
            border-color: $accentBase;
            background-color: $baseInverse;
            color: $accentBase;
        }
}

aside#artist-testimonials
{
    border-top: 5px solid $accentBase;
    padding: ($sectionPadding / 4) 0 0;
}

section#page-testimonials
{
    padding: 0 0 ($sectionPadding / 4);
}


aside#artist-testimonials,
section#page-testimonials
{
    .testimonial
    {
        padding: 30px 0;
    }
}

.testimonial
{
    border-bottom: 1px solid $accentBase;

    .testimonial-rating
    {
        text-align: left;
        display: inline-block;
        color: $accentBase;

        @media(min-width: $screen-sm-min)
        {
            width: 6.5em;
        }
    }

    &:last-child
    {
        border-bottom: 0;
        padding-bottom: 0;
    }
}

aside#page-alternates
{
    background-image: url(/site/img/bg/artists.svg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

aside#page-services
{
    background-image: url(/site/img/bg/services.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

aside#page-contact-block,
aside#page-services
{
    background-color: $baseInverse;
    border-top: 5px solid $accentBase;

    h3
    {
        color: $accentBase;
    }
}

.service-row
{
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
    justify-content: center;

    > .service-block,
    > .service-empty
    {
        flex: 0 1 calc(33% - 30px);
        margin: 0 15px 2em;
        min-width: 280px;

        @media(max-width: $screen-sm-max)
        {
            flex: 1 1 280px;
            max-width: 400px;
        }
    }

    &.service-highlighted .service-block
    {
        background-color: $highlight;
    }
}

.service-block
{
    display: block;
    background-color: $baseInverse;
    border-bottom: 5px solid $accentBase;
    padding: 22px 24px;
    margin-bottom: 2em;
    transition: transform ease-in-out 0.25s;
    box-shadow: $baseShadow;

    p
    {
        color: $baseText;
    }

    h4
    {
        text-transform: uppercase;
        color: $baseText;
        font-size: 1.333em;
    }

    &:hover
    {
        transform: scale(1.05);
    }

    &.form-block
    {
        background-color: $accentBase;
        border-bottom-width: 0;
        text-align: center;

        h4
        {
            color: $baseInverse;
        }

        .btn-primary
        {
            border-color: #fff;
        }
    }

    a.contact-link
    {
        font-size: 1.1em;

        &[href^=tel]
        {
            font-size: 2em;
        }

        @media(min-width: $screen-sm-min) and (max-width: $screen-sm-max)
        {
            font-size: 0.85em;

            &[href^=tel]
            {
                font-size: 1.8em;
            }
        }
    }

    @media(min-width: $screen-md-min) and (max-width: $screen-md-max)
    {
        .col-md-12
        {
            text-align: center;

            .img-responsive
            {
                max-width: 25%;
                margin: 0 auto;
            }

            h4
            {
                margin: 1.5em 0 0;
            }

            a.contact-link
            {
                font-size: 1.3em;

                &[href^=tel]
                {
                    font-size: 2em;
                }
            }
        }
    }
}

aside#page-callback
{
    background-color: $accentBase;
    padding: 50px 0 55px;

    h3
    {
        color: $baseInverse;
    }

    .btn-primary
    {
        border-color: #fff;
    }
}

footer#page-footer
{
    padding: 0 0 $sectionPadding;
    background-color: darken($highlight, 12.5%);
    background-image: url(/site/img/bg/footer.svg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    color: $baseText;
    font-weight: 500;

    h2
    {
        text-transform: uppercase;
        letter-spacing: 0.08em;
        margin: $sectionPadding 0 1.5em;
        font-size: 1.84em;
        position: relative;
        padding-bottom: 0.75em;

        &:after
        {
            content: "";
            position: absolute;
            border-bottom: $underlineHeight solid $accentBase;
            width: 15%;
            top: 100%;
            left: 0;
        }
    }

    @media(min-width: $screen-lg-min)
    {
        .col-md-3:nth-child(2)
        {
            padding-left: 60px;
        }
    }

    @media(max-width: $screen-xs-max)
    {
        text-align: center;

        h2:after
        {
            left: 50%;
            transform: translateX(-50%);
        }
    }
}

a.contact-link
{
    display: block;
    font-weight: bold;
    font-size: 1.5em;
    margin-bottom: 1em;

    &[href^=tel]
    {
        font-size: 2.4em;
        margin-bottom: 0.6667em;
    }

    @media(min-width: $screen-md-min) and (max-width: $screen-md-max)
    {
        font-size: 1.3em;
    }
}

ul.list-social
{
    margin: 0 0 1em;
    padding: 0;
    list-style-type: none;

    > li
    {
        display: inline-block;
        margin-right: 0.333em;

        &:last-child
        {
            margin-right: 0;
        }

        > a
        {
            font-size: 1.5em;
            height: 1.8em;
            width: 1.8em;
            text-align: center;
            line-height: 1.8em;
            display: block;
            border-radius: 50%;
            background-color: $baseInverse;
            color: $accentBase;
            transition: background-color ease-in-out 0.25s, color ease-in-out 0.25s;

            &:hover
            {
                background-color: $accentBase;
                color: $baseInverse;
            }
        }
    }
}

aside#page-twitter
{
    padding: 0;

    .tweet
    {
        padding: 6px 12px;
        border-radius: 6px;
        margin: 0 0 1em;
        background-color: $baseInverse;
        display: flex;
        overflow: hidden;
        font-weight: normal;

        .tweet-avatar
        {
            flex: 0 0 auto;
            margin: 0 1em 0 0;
        }

        .tweet-date
        {
            margin-top: 0.5em;
            font-size: 0.85em;
            font-family: $altFont;
        }
    }
}

footer#page-copyright
{
    border-top: 2px solid $accentBase;
    padding: 80px 0;
    font-family: $altFont;
    font-weight: 300;
    transform: translateZ(0);

    a
    {
        font-weight: normal;
    }

    .list-inline
    {
        a
        {
            color: $baseInverse;
            font-weight: 300;
        }

        > li
        {
            &:after
            {
                content: "|";
                margin-left: 11px;
                color: $accentBase;
                font-weight: normal;
            }

            &:last-child:after
            {
                content: none;
            }
        }
    }
}

.modal
{
    color: $baseText;

    iframe
    {
        width: 100%;
        height: 400px;
        max-height: 80vh;
        border: 0;
    }
}

section#contact-map
{
    padding: 0;
    background-color: $baseInverse;
    color: $baseText;
}

.sort-spacer
{
    display: inline-block;
    width: 2em;
}

.sort-spacer-arrow
{
    display: inline-block;
    width: 13px;
}

.table-actions > form
{
    display: inline-block;
}

.table-actions.table-actions-vertical > form
{
    display: block;
    margin-top: 0.25em;
}

.list-group-item > form,
.btn-group-forms > form
{
    display: inline;

    > button.badge
    {
        float: right;
        border: 0;
    }
}

.drop-area
{
    cursor: pointer;
    min-height: 180px;
    position: relative;
    width: 100%;
    border: 1px solid $accentBase;
    background-color: #fff;
    border-radius: 0;

    .drop-area-body
    {
        position: absolute;
        top: 50%;
        width: 100%;
        left: 0;
        text-align: center;
        padding: 2em;
        transform: translateY(-50%);
    }

    &:after
    {
        content: "";
        position: absolute;
        width: auto;
        height: auto;
        border: 2px dashed #ccc;
        left: 1em;
        top: 1em;
        right: 1em;
        bottom: 1em;
        border-radius: 0;
    }
}

.image-preview > img
{
    width: 320px;
    margin: 0 auto;
}

#crop-dialog img
{
    max-width: 100%;
}

.letter-selector
{
    margin-top: 50px;

    .pagination
    {
        margin-top: 0;
    }
}

@import "inc/_maps.scss";
