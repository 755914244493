﻿$mainFont: 'Raleway', sans-serif;
$altFont: 'Josefin Sans', sans-serif;

$baseFontSize: 16px;
$baseText: black;
$baseInverse: white;
$accentBase: #e31b6f;
$highlight: #eee;
$sectionPadding: 80px;
$baseShadow: 0 0 5px 0.5px rgba(0, 0, 0, 0.15);
$underlineHeight: 3px;


/* Borrowed from Bootstrap */

$brandSuccess: #5cb85c;
$brandInfo: #5bc0de;
$brandWarning: #f0ad4e;
$brandDanger: #d9534f;

// Extra small screen / phone
$screen-xs-min: 480px;

// Small screen / tablet
$screen-sm-min: 768px;

// Medium screen / desktop
$screen-md-min: 992px;

// Large screen / wide desktop
$screen-lg-min: 1200px;

$screen-xs-max: ($screen-sm-min - 1);
$screen-sm-max: ($screen-md-min - 1);
$screen-md-max: ($screen-lg-min - 1);