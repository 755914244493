﻿@import "_variables.scss";

#google-map
{
    width: 100%;
    height: 450px;
    box-sizing: content-box;

    *
    {
        box-sizing: content-box;
    }

    > div
    {
        filter: grayscale(99%);
    }
}

#google-map-error
{
    display: none;
}

.google-map-link
{
    color: $baseInverse;
    font-weight: 300;
    font-size: 14px;
}

#google-map-directions > div
{
    padding: 1em 0;
}

.adp-placemark td
{
    padding: 4px;
}

.adp-marker
{
    width: auto !important;
    height: 24px !important;
}

.adp-fullwidth td
{
    padding: 2px 4px;
}

.adp-directions
{
    table-layout: fixed;
    width: 100%;

    td:nth-child(1),
    td:nth-child(2)
    {
        width: 5% !important;
    }

    td:nth-child(4)
    {
        width: 10% !important;
    }
}

.adp-substep
{
    word-wrap: break-word;
}

@media(max-width: $screen-sm-max)
{
    #google-map-from
    {
        margin-bottom: 10px;
    }
}
